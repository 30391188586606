import { Component, OnInit, Inject } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-resend-email',
	templateUrl: './resend-email.component.html',
	styleUrls: ['./resend-email.component.scss']
})
export class ResendEmailComponent implements OnInit {
	submitted = false;
	loading = false;
	constructor(
		public gs: GlobalService,
		public commonService: CommonService,
		private router: Router,
		private dialogRef: MatDialogRef<ResendEmailComponent>,
		@Inject(MAT_DIALOG_DATA) public data,
	) { }

	ngOnInit() {
		
	}

	submit() {
		this.submitted = true;
		this.commonService.resendEmial(this.data.code).subscribe((data: any) => {
			this.gs.alert('Please check your email for verification');
			this.router.navigate(['/otp-verification']);
			this.dialogRef.close();
		}, (error: Response) => {
			this.gs.handleErrors(error);
			this.submitted = false;
		});
	}

	

	closeDialog() {
		this.dialogRef.close();
	}

}
