import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { Observable } from 'rxjs';
import { AuthSelector } from 'src/app/store/selector';
import { Store } from '@ngrx/store';

@Component({
	selector: 'app-timeline-menu',
	templateUrl: './timeline-menu.component.html',
	styleUrls: ['./timeline-menu.component.scss']
})
export class TimelineMenuComponent implements OnInit {
	userObservable: Observable<User> = this.store.select(AuthSelector.User);
    user: User = null;
	constructor(
		private store: Store<{}>
	) { }

	ngOnInit() {
		this.userObservable.subscribe(data => this.user = data);
	}

}
