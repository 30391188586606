import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, Inject, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Post } from '../../../models/Post';
import { ReportAbuseComponent } from '../report-abuse/report-abuse.component';
import { GlobalService } from '../../../helpers/global.service';
import { ImagePopupComponent } from '../image-popup/image-popup.component';
import { ShowAnalyticsComponent } from '../show-analytics/show-analytics.component';
import { SharePostComponent } from '../share-post/share-post.component';
import { CommonService } from 'src/app/service/common.service';
import { PostEmbedComponent } from '../post-embed/post-embed.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserService } from 'src/app/service/user.service';
import { LikeUserListComponent } from '../like-user-list/like-user-list.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ItemService } from 'src/app/service/item.service';
import { ShowBlockUserPostComponent } from '../show-block-user-post/show-block-user-post.component';
import { Store } from '@ngrx/store';
import { AuthActions, CommunityTimelineAction, EventTimelineAction, ExploreTimelineAction, FeedTimelineAction, GroupTimelineAction, HiddenPostTimelineAction, HideAllPostTimelineAction, HidedMyPostTimelineAction, PageTimelineAction, SchedulePostTimelineAction, UserTimelineAction } from 'src/app/store/action';
import { User } from 'src/app/models/User';
import { interval, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-post',
    templateUrl: './post.component.html',
    styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit, OnDestroy {
    apiRoot: string = environment.hostName;
    module = 'Post';
    @Input() public item: Post = null;
    @Input() public reactionsList: any = null;
    @Input() public pinPost: any = null;
    @Input() public hiddenPost: any = null;
    @Input() public hiddenMyPost: any = null;
    @Input() public viewPost: boolean = true;
    @Input() public userTimelinePin: boolean = false;
    @Input() public savePostList: boolean = false;
    @Input() public myTimeline: boolean = false;
    @Input() public timeline: string = 'Feed';
    @Output() public postRemove = new EventEmitter();
    @Output() public pinPostRemove = new EventEmitter();

    //@Input() public shareModule:string = null;
    postDialogRef: MatDialogRef<ReportAbuseComponent>;
    imgDialogRef: MatDialogRef<ImagePopupComponent>;
    showAnalyticsRef: MatDialogRef<ShowAnalyticsComponent>;
    showBlockUserListRef: MatDialogRef<ShowBlockUserPostComponent>;
    likeUserListRef: MatDialogRef<LikeUserListComponent>;
    shareDialogRef: MatDialogRef<SharePostComponent>;
    embedDialogRef: MatDialogRef<PostEmbedComponent>;
    userProfile: User = null;
    templateModule = null;
    moduleTag = null;
    videoCount = null;
    showCommentList: boolean = false;
    showCommentForm: boolean = false;
    liveStremUrl = environment.restStreamApi;

    StreamVideo = '';
    subscription$;

    is_share_own_post: boolean = false;

    constructor(
        public gs: GlobalService,
        private dialog: MatDialog,
        private commonService: CommonService,
        private itemService: ItemService,
        public userService: UserService,
        private store: Store<{}>,
        @Inject(DOCUMENT) document
    ) {
    }

    ngOnInit() {
        this.userProfile = JSON.parse(localStorage.getItem('user'));
        if (this.viewPost) {
            if (this.item.module) {
                let mo = this.item.module;
                let module = mo.split('\\');
                this.moduleTag = module.pop();
            }
            if (this.item.refer_module) {
                let mo = this.item.refer_module;
                let module = mo.split('\\');
                this.templateModule = module.pop();
            }
        }
        //this.StreamVideo = `${this.liveStremUrl}/${this.item?.postVideos?.stream_type}/${this.item?.postVideos?.url}`;
        this.StreamVideo = this.item?.postVideos?.url;
        //liveStremUrl + '/' + + item.postVideos.type + '/' + item.postVideos.url
        // Subscribe to begin publishing values
        // https://media2.chatdit.com:5443/LiveApp/https://media2.chatdit.com:5443/LiveApp/play.html
        //https://media2.chatdit.com:5443/LiveApp/play.html?name=streams/971032824636928764246370.mp4
        // https://media2.chatdit.com:5443/LiveApp/streams/144568132751816774549816_1.mp4
        // https://media2.chatdit.com:5443/LiveApp/play.html?name=streams/144568132751816774549816_1.mp4
        if (!this.viewPost) {
            var observable$ = interval(1000);
            this.subscription$ = observable$.subscribe(x => {
                //console.log('postVideos_live', x) video-player_html5_api
            })
        }
        if(this.item.parent_id && this.item.content === '' || this.item.content === undefined || this.item.content === null) {
            this.is_share_own_post = true;
        }
    }

    copyToClipboard() {
        let url = this.apiRoot + '/timeline/post-view/' + this.item.id;
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = url;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.gs.alert('Copied');
    } // Copy Link to post

    embedPost() {
        let embedUrl = this.apiRoot + '/timeline/embed-post/' + this.item.id;
        this.embedDialogRef = this.dialog.open(PostEmbedComponent, {
            width: '1140px',
            disableClose: true,
            panelClass: 'embed-dialog',
            data: embedUrl
        });
    } // Embed post

    savePost() {
        this.itemService.post("POST", null, { key: 'saved', post_id: this.item.id }).subscribe(data => {
            const item = Object.assign({}, this.item, { isSaved: 1 });
            this.timelineApp(item);
            this.gs.alert('You have successfully save post.');
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
    } // Save post

    unsavePost() {
        this.itemService.post("POST", null, { key: 'saved', post_id: this.item.id }).subscribe(data => {
            const item = Object.assign({}, this.item, { isSaved: 0 });
            this.timelineApp(item);
            if (this.savePostList === true) {
                this.timelineDelete(item);
            }
            this.gs.alert('You have successfully unsave post.');
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
    } // Unsave post

    hidePost(action?, key?) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: `Do you want to ${action} this post from your timeline?`
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.itemService.post("POST", null, { key: key, post_id: this.item.id }).subscribe((data: any) => {
                    this.timelineDelete(this.item);
                    this.gs.alert(`You have successfully ${action} post.`);
                }, (error: Response) => {
                    this.gs.handleErrors(error);
                });
            }
        });
    } // Hide post from timeline

    hideAllPostsThisUser(item) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: "Do you want to hide all posts from this user?"
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.itemService.post("POST", null, { key: 'hide-all-post', user_id: item.user_id }).subscribe(data => {
                    (data.code === false) ?
                        this.gs.alert('You have successfully hide all posts from this user.') : this.gs.alert('You have successfully hide all posts from this user.');
                    this.itemService.followCount('hideAllCommentThisUser');
                }, (error1 => {
                    this.gs.handleErrors(error1);
                }));
            }
        });
    }

    deletePost(msg) {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: msg
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.itemService.post("DELETE", null, { post_id: this.item.id }).subscribe((data: any) => {
                    this.timelineDelete(this.item);
                    this.gs.alert('You have successfully delete post.');
                }, (error: Response) => {
                    this.gs.handleErrors(error);
                });
            }
        });
    } // Delete post to timeline

    pinToTop() {
        this.itemService.post("POST", null, { post_id: this.item.id, key: 'pin-post' }).subscribe(data => {
            this.gs.alert(this.pinPost ? 'Pin post removed.' : 'Pin post added.');
            this.pinPostRemove.emit(this.item);
            this.timelineDelete(this.item);
            this.commonService.pinPostAdd('pin_post_add');
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
    } // Pin to top

    likePost(reaction) {
        let item_id = this.is_share_own_post ? this.item.parent.id : this.item.id;
        const params = {
            "item_id": item_id,
            "module": 'Post',
            "reaction_id": reaction.id
        }
        this.itemService.reaction("POST", params).subscribe(data => {
            const item = Object.assign({}, this.item, { reactionCount: data.data.reactionsCount, currentReaction: data.data.reaction.title });
            this.timelineUpdate(item);
            this.gs.alert('You have successfully like post in timeline.');
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
    } // Like post

    reportAbuse() {
        this.postDialogRef = this.dialog.open(ReportAbuseComponent, {
            width: '550px',
            disableClose: true,
            panelClass: 'custom-dialog',
            data: {
                id: this.item.id,
                module: 'Post',
            }
        });
    } // Post report abuse

    openImage(id, imgGroup) {
        this.imgDialogRef = this.dialog.open(ImagePopupComponent, {
            disableClose: false,
            panelClass: 'popup-dialog',
            data: {
                id: id,
                imgGroup: imgGroup,
                module: 'Photo',
                owner: this.item.owner,
                postDate: this.item.created_at,
                reactionsList: this.reactionsList,
            }
        });
    } // Post image popup slider

    sharePost(item) {
        this.shareDialogRef = this.dialog.open(SharePostComponent, {
            width: '550px',
            disableClose: true,
            panelClass: 'custom-dialog',
            data: item
        });
        this.shareDialogRef.afterClosed().pipe().subscribe(name => {
            this.commonService.setAction('create_post');
        });
    } // Share Post


    // Follow user
    followUser(item, action) {
        const method = (action === 'follow') ? "POST" : "DELETE";
        const bg = (action === 'follow') ? "bg-success" : "bg-danger";
        this.userService.follow(method, null, { following_id: item.owner.id }).subscribe(data => {
            this.gs.alert(`You have successfully ${action} this user.`, bg);
            this.itemService.followCount('update_count');
            const item = Object.assign({}, this.item, { is_follow: (action === 'follow') ? true : false });
            this.timelineUpdate(item);
            this.viewProfile((action === 'follow') ? 'is_user_follow' : 'is_user_unfollow');
        }, (error: Response) => {
            this.gs.handleErrors(error);
        });
    }

    isOwner(userId) {
        return (this.item.owner.id === userId);
    }

    // Block This User Permanently

    blockUser(key?) {
        const alert = (key === 'permanently') ? `Do you want to ${(this.item.owner.friendState === 5) ? 'Unblock' : 'Block'} This User Permanently?` : `Do you want to take a 30 day ${(this.item.owner.friendState === 0) ? 'unblock' : 'block'} from seeing this user?`;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: alert
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.itemService.block("POST", null, { key: 'permanently', friend_id: this.item.owner.id }).subscribe(data => {
                    const item = data.data;
                    const msg = (item.status === 1) ? 'You have successfully unblock this user.' : 'You have successfully block this user.';
                    this.gs.alert(msg);
                    this.viewProfile('update_count');
                    if (key === 'permanently' && item.status === 5) {
                        const item = Object.assign({}, this.item, { friendState: 5 });
                        this.timelineUpdate(item);
                    } else if (key === 'temporary' && item.status === 4) {
                        const item = Object.assign({}, this.item, { friendState: 4 });
                        this.timelineUpdate(item);
                    } else {
                        const item = Object.assign({}, this.item, { friendState: 1 });
                        this.timelineUpdate(item);
                    }
                    window.location.reload();
                }, (error1 => {
                    this.gs.handleErrors(error1);
                }));
            }
        });
    }

    showAnalytics() {
        this.showAnalyticsRef = this.dialog.open(ShowAnalyticsComponent, {
            width: '550px',
            disableClose: false,
            panelClass: 'custom-dialog',
            data: this.item
        });
    }


    likeUserList(item) {
        this.likeUserListRef = this.dialog.open(LikeUserListComponent, {
            width: '650px',
            disableClose: false,
            panelClass: 'custom-dialog',
            data: {
                post: item,
                reaction: this.reactionsList
            }
        });
    }

    turnOnNotification() {
        alert('Work in progress');
    }

    blockUserList() {
        this.showBlockUserListRef = this.dialog.open(ShowBlockUserPostComponent, {
            width: '550px',
            disableClose: false,
            panelClass: 'custom-dialog',
            data: this.item
        });
    }

    viewProfile(action?) {
        this.userService.profile().subscribe((response: any) => {
            this.store.dispatch(new AuthActions.loginSuccess({ user: response }));
        });
    }

    displayCommentList() {
        this.showCommentList = !this.showCommentList;
    }

    displayCommentForm() {
        this.showCommentForm = !this.showCommentForm;
    }

    returnUrl() {
        // '/timeline/post-view/' + this.item.id
        //`${environment.hostName}/timeline/post-view/${this.item.id}`;
        return `${environment.hostName}/timeline/post-view/${this.item.id}`;
    }

    updateCmnt(event) {
        const item = Object.assign({}, this.item, { commentCount: Number(this.item.commentCount) + 1 });
        this.timelineUpdate(item);
    }

    timelineApp(item) {
        switch (this.timeline) {
            case 'Feed':
                this.store.dispatch(new FeedTimelineAction.Add(item));
                break;
            case 'Explore':
                this.store.dispatch(new ExploreTimelineAction.Add(item));
                break;
            case 'user_post':
                this.store.dispatch(new UserTimelineAction.Add(item));
                break;
            case 'group_post':
                this.store.dispatch(new GroupTimelineAction.Add(item));
                break;
            case 'page_post':
                this.store.dispatch(new PageTimelineAction.Add(item));
                break;
            case 'event_post':
                this.store.dispatch(new EventTimelineAction.Add(item));
                break;
            case 'community_post':
                this.store.dispatch(new CommunityTimelineAction.Add(item));
                break;
            case 'hidden_post':
                this.store.dispatch(new HiddenPostTimelineAction.Add(item));
                break;
            case 'hidedmy_post':
                this.store.dispatch(new HidedMyPostTimelineAction.Add(item));
                break;
            case 'hideall_post':
                this.store.dispatch(new HideAllPostTimelineAction.Add(item));
                break;
            case 'schedule_post':
                this.store.dispatch(new SchedulePostTimelineAction.Add(item));
                break;
            case 'view':
                this.store.dispatch(new UserTimelineAction.View(item));
                break;
            default: ;
        }
    }

    timelineUpdate(item) {
        switch (this.timeline) {
            case 'Feed':
                this.store.dispatch(new FeedTimelineAction.Update(item));
                break;
            case 'Explore':
                this.store.dispatch(new ExploreTimelineAction.Update(item));
                break;
            case 'user_post':
                this.store.dispatch(new UserTimelineAction.Update(item));
                break;
            case 'group_post':
                this.store.dispatch(new GroupTimelineAction.Update(item));
                break;
            case 'page_post':
                this.store.dispatch(new PageTimelineAction.Update(item));
                break;
            case 'event_post':
                this.store.dispatch(new EventTimelineAction.Update(item));
                break;
            case 'community_post':
                this.store.dispatch(new CommunityTimelineAction.Update(item));
                break;
            case 'hidden_post':
                this.store.dispatch(new HiddenPostTimelineAction.Update(item));
                break;
            case 'hidedmy_post':
                this.store.dispatch(new HidedMyPostTimelineAction.Update(item));
                break;
            case 'hideall_post':
                this.store.dispatch(new HideAllPostTimelineAction.Update(item));
                break;
            case 'schedule_post':
                this.store.dispatch(new SchedulePostTimelineAction.Update(item));
                break;
            case 'view':
                this.store.dispatch(new UserTimelineAction.View(item));
                break;
            default: ;
        }
    }

    timelineDelete(item) {
        switch (this.timeline) {
            case 'Feed':
                this.store.dispatch(new FeedTimelineAction.Delete(item));
                break;
            case 'Explore':
                this.store.dispatch(new ExploreTimelineAction.Delete(item));
                break;
            case 'user_post':
                this.store.dispatch(new UserTimelineAction.Delete(item));
                break;
            case 'group_post':
                this.store.dispatch(new GroupTimelineAction.Delete(item));
                break;
            case 'page_post':
                this.store.dispatch(new PageTimelineAction.Delete(item));
                break;
            case 'event_post':
                this.store.dispatch(new EventTimelineAction.Delete(item));
                break;
            case 'community_post':
                this.store.dispatch(new CommunityTimelineAction.Delete(item));
                break;
            case 'hidden_post':
                this.store.dispatch(new HiddenPostTimelineAction.Delete(item));
                break;
            case 'hidedmy_post':
                this.store.dispatch(new HidedMyPostTimelineAction.Delete(item));
                break;
            case 'hideall_post':
                this.store.dispatch(new HideAllPostTimelineAction.Delete(item));
                break;
            case 'schedule_post':
                this.store.dispatch(new SchedulePostTimelineAction.Delete(item));
                break;
            case 'view':
                this.store.dispatch(new UserTimelineAction.View(item));
                break;
            default: ;
        }
    }

    myFunction() {
        var iframe: any = document.getElementById('myFrame');
        let iWindow = iframe.contentWindow
        let doc = iframe.contentDocument || iframe.contentWindow.document;
        let vid = doc.getElementById('postVideos_live');
        console.log('iframe', doc);
        console.log('vid.currentTime', vid.currentTime);
        //vid.currentTime = 5;
        //document.getElementById("myFrame");
        //var elmnt = iframe.contentWindow.document.getElementsByTagName("H1")[0];
        //elmnt.style.display = "none";
    }

    ngOnDestroy() {
        if (!this.viewPost) {
            this.subscription$.unsubscribe();
        }
    }

}
