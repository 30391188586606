import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { MaterialModule } from '../material-module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { NgxStripeModule } from 'ngx-stripe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { MentionModule } from 'angular-mentions';

// Components
import { PostComponent } from './components/post/post.component';
import { CreatePostComponent } from './components/create-post/create-post.component';
import { ReportAbuseComponent } from './components/report-abuse/report-abuse.component';
import { AddPhotoComponent } from './components/add-photo/add-photo.component';

import { PostCommentListComponent } from './components/post-comment-list/post-comment-list.component';
import { PostCommentFormComponent } from './components/post-comment-list/post-comment-form/post-comment-form.component';
import { PostCommentReplyFormComponent } from './components/post-comment-list/post-comment-reply-form/post-comment-reply-form.component';
import { CommentComponent } from './components/post-comment-list/comment/comment.component';

import { ReadMoreComponent } from './directive/read-more/read-more.component';
import { SocialShareDirective } from './directive/social-share.directive';

import { ParseUrl } from './parse-url.pipe';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { ViewModule } from '../view/view.module';
import { SharePostComponent } from './components/share-post/share-post.component';
import { PostEmbedComponent } from './components/post-embed/post-embed.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { EditPostComponent } from './components/edit-post/edit-post.component';
import { EditPostDateComponent } from './components/edit-post-date/edit-post-date.component';
import { LoginPopupComponent } from './login-popup/login-popup.component';
import { StripePaymentComponent } from './components/stripe-payment/stripe-payment.component';
import { SendMessageComponent } from './components/send-message/send-message.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { HighlightDirective } from './directive/highlight.directive';
import { AutoplayVideoDirective } from './directive/autoplay-video.directive';
import { AutoplayContentComponent } from './directive/autoplay-content.component';
import { PrivacyExpectComponent } from './components/privacy-expect/privacy-expect.component';
import { PrivacySpecificComponent } from './components/privacy-specific/privacy-specific.component';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { ShowAnalyticsComponent } from './components/show-analytics/show-analytics.component';
import { LikeUserListComponent } from './components/like-user-list/like-user-list.component';
import { CarouselModule } from "ngx-owl-carousel-o";
import { environment } from "../../environments/environment";
import { SafeWidgetPipe } from "../helpers/safe.widget.pipe";
import { AnnouncementVideoComponent } from './components/announcement-video/announcement-video.component';
import { AdvertisementComponent } from './components/advertisement/advertisement.component';
import { LiveStreamComponent } from './components/live-stream/live-stream.component';
import { UtcDatePipe } from './pipe/utcDate';
import { ShowBlockUserPostComponent } from './components/show-block-user-post/show-block-user-post.component';
import { FriendBoxComponent } from './components/people-you-may-know/friend-box/friend-box.component';
import { PeopleYouMayKnowComponent } from './components/people-you-may-know/people-you-may-know.component';
import { NgxMatDateAdapter, NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { TimeAgoPipe } from './pipe/time-ago.pipe';
import { PostCommentsComponent } from './components/post-comment-list/post-comments/post-comments.component';
import { LiveVideoPlayComponent } from './components/live-video-play/live-video-play.component';
import { PhoneMaskDirective } from './directive/phone.mask.directive';

var CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
	parse: {
	  dateInput: "l, LTS"
	},
	display: {
	  dateInput: "l, LTS",
	  monthYearLabel: "MMM YYYY",
	  dateA11yLabel: "LL",
	  monthYearA11yLabel: "MMMM YYYY"
	}
  };

@NgModule({
	declarations: [PostComponent, CreatePostComponent, ReportAbuseComponent, AddPhotoComponent, PostCommentFormComponent, PostCommentListComponent, PostCommentReplyFormComponent, ReadMoreComponent, ParseUrl, CommentComponent, ImagePopupComponent, SharePostComponent, PostEmbedComponent, ConfirmationDialogComponent, EditPostComponent, EditPostDateComponent, LoginPopupComponent, StripePaymentComponent, SendMessageComponent, HighlightDirective, AutoplayVideoDirective, PrivacyExpectComponent, PrivacySpecificComponent, AnnouncementComponent, ShowAnalyticsComponent, LikeUserListComponent, SafeWidgetPipe, AutoplayContentComponent, AnnouncementVideoComponent, AdvertisementComponent, LiveStreamComponent, UtcDatePipe, ShowBlockUserPostComponent, PeopleYouMayKnowComponent, FriendBoxComponent, TimeAgoPipe, SocialShareDirective, PostCommentsComponent, LiveVideoPlayComponent, PhoneMaskDirective],
	imports: [
		CommonModule,
		MatNativeDateModule,
		MaterialModule,
		NgbModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		LaddaModule.forRoot({
			style: 'expand-right',
			spinnerSize: 30
		}),
		RouterModule,
		ViewModule,
		PickerModule,
		EmojiModule,
		InfiniteScrollModule,
		NgScrollbarModule,
		CarouselModule,
		MentionModule,
		NgxMatTimepickerModule,
		NgxMatDatetimePickerModule,
		NgxMatMomentModule,
		NgxStripeModule.forRoot(environment.STRIPE_PUBLIC_KEY)
	],
	entryComponents: [ReportAbuseComponent, AddPhotoComponent, ImagePopupComponent, SharePostComponent, PostEmbedComponent, ConfirmationDialogComponent, EditPostComponent, EditPostDateComponent, LoginPopupComponent, SendMessageComponent, PrivacyExpectComponent, PrivacySpecificComponent, ShowAnalyticsComponent, LikeUserListComponent, AnnouncementVideoComponent, LiveStreamComponent, ShowBlockUserPostComponent],
	exports: [PostComponent, CreatePostComponent, PostCommentFormComponent, PostCommentListComponent, PostCommentReplyFormComponent, AnnouncementComponent, StripePaymentComponent, ShowAnalyticsComponent, LikeUserListComponent, AutoplayVideoDirective, AutoplayContentComponent, AdvertisementComponent, HighlightDirective, UtcDatePipe, PeopleYouMayKnowComponent, FriendBoxComponent, TimeAgoPipe, SocialShareDirective, ReadMoreComponent, LiveVideoPlayComponent, PhoneMaskDirective],
	providers: [
		{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
	]
})

export class WidgetsModule {

}
