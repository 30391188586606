<form class="d-flex w-100" novalidate (ngSubmit)="postComment(form)" [formGroup]="form" autocomplete="off" [ngClass]="(editComment === true) ? 'edit-comment-form' : ''">
	<div class="profile">
		<img src="{{user.avatar}}" class="img-fluid border rounded-circle" alt="">
	</div>
	<div class="caption d-flex align-items-end" >
		<ngx-emoji emoji="santa" set="emojione" size="16"></ngx-emoji>
		<mat-form-field appearance="outline" class="col" formGroupName="CommentForm">
			<textarea matInput placeholder="What's on your mind?" formControlName="content" name="text" #commentText></textarea>
		</mat-form-field>
		<div class="buttons">
			<div *ngIf="(editComment === true)" class="w-100 text-center">
				<a (click)="cancelUpdate()" style="cursor: pointer">Cancel</a> 
			</div>
			<div class="outside-click" (clickOutside)="show = false">
				<a mat-stroked-button color="primary" (click)="toggle()"><i class="far fa-smile"></i></a>
				<ng-container *ngIf="show">
					<emoji-mart (emojiClick)="addEmojiPost($event)" title="Pick your emoji"
						[showPreview]="false" [emojiTooltip]="true" set="facebook"
						[style]="{ position: 'absolute', top: '35px', right: '-2px', zIndex: '9999' }"></emoji-mart>
				</ng-container>
			</div>
			<button type="submit" [disabled]="!form.valid" [ladda]="submitted" class="btn btn-primary btn-post" *ngIf="(editComment === false)">Post</button>
			<button type="submit" [disabled]="!form.valid" [ladda]="submitted" class="btn btn-success btn-post" *ngIf="(editComment === true)">Update</button>
		</div>
	</div> 
</form>