
<div *ngIf="advertisements">
    <owl-carousel-o [options]="advertisementtOptions">
        <ng-container *ngFor="let slide of advertisements | async">
            <ng-template carouselSlide [id]="slide.id">
                <div class="ad-box bg-white p-3">
                    <a [href]="slide && slide.advertisement_link" target="_blank">
                        <img [src]="slide && slide.advertisement_image && slide.advertisement_image.thumb" alt="" class="img-fluid w-100 mb-2">
                        <div class="text-center">
                            <a [href]="slide && slide.advertisement_link" target="_blank" class="btn btn-primary btn-post">Buy Now</a>
                        </div>
                    </a> 
                </div>
            </ng-template>
        </ng-container>

    </owl-carousel-o>
</div>