import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/helpers/global.service';
import { CommonService } from 'src/app/service/common.service';
import { Comment } from 'src/app/models/Comment';
import { ItemService } from 'src/app/service/item.service';
import { Post } from 'src/app/models/Post';

@Component({
	selector: 'app-post-comment-list',
	templateUrl: './post-comment-list.component.html',
	styleUrls: ['./post-comment-list.component.scss']
})
export class PostCommentListComponent implements OnInit {
	@Input() public module;
	@Input() public myTimeline: boolean = false;
	@Input() public isPopup: boolean = false;
	@Input() public item: Post = null;
	@Input() public timeline: string = 'Feed';

	loading = false;
	comments: Comment[] = [];
	// Pagination
	page: number = 1;
	perPage: number = null;
	totalCount: number = null;
	pageCount: number = null;

	constructor(
		public itemService: ItemService,
		public gs: GlobalService,
		public commonService: CommonService,
	) { }

	ngOnInit() {

		this.loadComments(true, true);

		this.itemService.popupCommentList$.subscribe(data => {
			//console.log('datadatadata', data)
			if (data.item_id === this.item.id) {
				//this.comments.unshift(data);
				this.page += 1;
				this.loadComments(true, true);
			}
		});

		this.itemService.commentReplyId$.subscribe(data => {
			this.page += 1;
			this.loadComments(true, true);
			/*this.comments.forEach(comment => {
				if (parseInt(data.parent_id) === comment.id) {
					comment.userComments.push(data);
				}
			});*/
		});

		if (this.isPopup) {
			this.itemService.commentId$.subscribe(action => {
				//this.commentsPopup(action, 'Photo', this.page);
			});
		}
	}

	loadComments(loading = true, loadMore = true) {
		this.loading = loading;
		this.itemService.comment("GET", null, { item_id: this.item.id, module: this.module, page: this.page, 'per-page': 5 }).subscribe((data: any) => {
			let commentList = data.data?.items;
			let pagination = data.data?.pagination;
			if(loadMore) {
				this.comments = commentList;
			} else {
				commentList?.forEach(comment => {
					const index = this.comments.findIndex(f => f.id == comment.id);
					if (index !== -1) {
						this.comments[index] = comment;
					} else {
						this.comments.push(comment);
					}
				});
			}
			this.totalCount = pagination && pagination.totalCount;
			this.pageCount = pagination && pagination.pageCount;
			this.perPage = pagination && pagination.pageSize;
			this.loading = false;
		});
	}

	pagination() {
		this.page += 1;
		this.loadComments(true, false);
	}

	newLoadComment(event) {
		console.log('newLoadComment newLoadComment', event)
		this.loadComments(false, true);
	}

}
