<div class="blog-list w-100 float-left py-4">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="card page-card">
					<div class="card-header d-flex flex-wrap align-items-center">
						<ng-container *ngIf="requestLoading else loaded">
							<h3 class="card-title col pl-0">{{page.title}} </h3>
						</ng-container>
						<ng-template #loaded>
							<div class="d-flex align-items-center justify-content-center w-100">
								<mat-spinner color="primary" class="spinner-size"></mat-spinner>
							</div>
						</ng-template>
					</div>
					<div class="card-body">
						<ng-container *ngIf="requestLoading else loaded">
							<div [innerHtml]="page.content | safeHtml"></div> 
						</ng-container>
						<ng-template #loaded>
							<div class="d-flex align-items-center justify-content-center w-100">
								<mat-spinner color="primary" class="spinner-size"></mat-spinner>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>