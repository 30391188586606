
<div class="news-page w-100 float-left">
	<div class="container">
		<div class="livetxt">Live Video</div>
		<div class="card page-card border-0 card-one">
			<div class="card-body p-0">
				<app-home-live-video></app-home-live-video>
			</div>
		</div>
	</div>

	<div class="container">
		<div class="card page-card border-0 feature">
			<div class="card-body">
				<app-feature-news></app-feature-news>
			</div>
		</div>
	</div>

	<div class="container">
		<div class="row">
			<div class="col-sm-9">
				<div class="card page-card card-black top-arrow card-two">
					<div class="card-body">
						<div class="card-header d-flex flex-wrap align-items-center">
							<h3 class="card-title col pl-0"><span class="bg-black text-white">Latest</span></h3>
						</div>
						<app-category-widgets [widgetClass]="'word-news'"></app-category-widgets>
					</div>
				</div>

				<div class="card page-card card-danger top-arrow politics">
					<div class="card-body">
						<div class="card-header d-flex flex-wrap align-items-center">
							<h3 class="card-title col pl-0"><span class="bg-danger text-white">Diamond and Silk Store</span></h3>
						</div>
						<app-category-widgets [widgetClass]="'politics'"></app-category-widgets>
					</div>
				</div>

			</div>
			<div class="col-sm-3">
				<!-- <div class="card page-card card-story">
					<div class="card-header border-bottom-0">
						<h3 class="card-title">Latest  Videos</h3>
					</div>
					<div class="card-body">
						<div *ngFor="let item of allpost | slice:1:4" class="w-100 video-list mb-2">
							<a routerLink="/timeline/post-view/{{item.id}}">
								<div class="d-flex position-relative">
									<img [src]="item.postVideos.image ? item.postVideos.image.thumb : 'https://uploads.dev.chatdit.com/cache/f/1/K/p/w/T/n/v/f1KpwTnvU2cktUd7uNOkks0v_L01CWLS-500x600.jpg'" alt="" class="img-fluid mr-2" width="100">
									<div>
										<h5 style="max-height: 68px; overflow: hidden;" class="mt-0">{{item.content ? item.content : 'No Details Available' | slice:0: 30 }}</h5>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div> -->
				<div class="card page-card card-story">
					<div class="card-header border-bottom-0">
						<h3 class="card-title">Advertisers Block</h3>
					</div>
					<div class="card-body">
						<app-right-news [widgetClass]="'business'"></app-right-news>
					</div>
				</div>
				<div class="card page-card card-story subscribe">
					<div class="card-header border-bottom-0">
						<h3 class="card-title">Subscribe</h3>
					</div>
					<div class="card-body">
						<app-subscribe [title]="'Get Lates Updated Please Enter Your Phone Number and Email Addrees'"></app-subscribe>
					</div>
				</div>
			</div>
		</div>
	</div>

	

	<div class="container">
		<div class="card page-card card-black top-arrow entertainmentbox">
			<div class="card-body">
				<div class="card-header d-flex flex-wrap align-items-center">
					<h3 class="card-title col pl-0"><span class="bg-black text-white">DS Chit Chat</span></h3>
				</div>

				<ng-container *ngIf="loading else loaded">
					<div class="d-flex align-items-center justify-content-center w-100">
						<mat-spinner color="primary" class="spinner-size"></mat-spinner>
					</div>
					
					<!-- <owl-carousel-o [options]="storyOptions">
						<ng-template carouselSlide *ngFor="let news of items">
							<ul class="item entertainment">
								<li *ngFor="let item of news">
									<div class="ctn-new-list">
										<ng-container *ngIf="item.external_url else loaded2">
											<a [href]="item.external_url" target="_blank">
												<div class="ctn-news-img">
													<img src="{{item.live_image_url}}" alt="Image">
												</div>
												<div class="detail">
													<span>{{item.title | slice:0:50}}</span>
													<p [innerHtml]="item.content | slice:0:70"></p>
													<a [href]="item.external_url ? item.external_url : '/news/view/'+item.slug" target="_blank" class="sports_readmore">Read More ....</a>
												</div>
											</a>
										</ng-container>
										<ng-template #loaded2>
											<a routerLink="/news/view/{{item.slug}}">
												<div class="ctn-news-img">
													<img src="{{ item.live_image_url }}" alt="Image">
												</div>
												<div class="detail">
													<span>{{item.title | slice:0:50}}</span>
													<p [innerHtml]="item.content | slice:0:70"></p>
													<a [href]="item.external_url ? item.external_url : '/news/view/'+item.slug" target="_blank" class="sports_readmore">Read More ....</a>
												</div>
											</a>
										</ng-template>
									</div>
								</li>
							</ul>
						</ng-template>
					</owl-carousel-o> -->
				</ng-container>
				<ng-template #loaded>
					<ul class="item latest-videos-list">
						<li *ngFor="let item of allpost">
							<div class="ctn-new-list">
								<a routerLink="/timeline/post-view/{{item.id}}">
									<div class="ctn-news-img">
										<img src="{{item.postVideos.image ? item.postVideos.image.thumb : 'https://uploads.dev.chatdit.com/cache/f/1/K/p/w/T/n/v/f1KpwTnvU2cktUd7uNOkks0v_L01CWLS-500x600.jpg'}}" alt="Image">
									</div>
									<div class="detail">
										<!-- <span>{{item.title | slice:0:50}}</span> -->
										<p [innerHtml]="item.content ? item.content : 'No Details Available'"></p>
										<a routerLink="/timeline/post-view/{{item.id}}" class="sports_readmore">Read More ....</a>
									</div>
								</a>
							</div>
						</li>
					</ul>
					<div *ngIf="allpost?.length == 0">Video not found</div>
				</ng-template>

				<!-- <app-category-widgets [widgetClass]="'entertainment'"></app-category-widgets> -->

			</div>
		</div>
		<div class="card page-card card-success top-arrow travelinfluencer">
			<div class="card-body">
				<div class="card-header d-flex flex-wrap align-items-center">
					<h3 class="card-title col pl-0"><span class="bg-success text-white">Events </span></h3>
				</div>
				<app-category-widgets [widgetClass]="'lifestyle'"></app-category-widgets>
			</div>
		</div>
		<div class="card page-card card-danger top-arrow sportsbox">
			<div class="card-body">
				<div class="card-header d-flex flex-wrap align-items-center">
					<h3 class="card-title col pl-0"><span class="bg-danger text-white">Announcements</span></h3>
				</div>
				<app-category-widgets [widgetClass]="'sports'"></app-category-widgets>
			</div>
		</div>
	</div>
</div>
