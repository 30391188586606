import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { GlobalService } from 'src/app/helpers/global.service';
import { CommonService } from 'src/app/service/common.service';
import * as AuthAction from '../action/auth.actions';


@Injectable()
export class AuthEffects {

	constructor(
		private actions$: Actions,
		private authService: AuthService,
		private commonService: CommonService,
		private router: Router,
		private gs: GlobalService
	) { }

	@Effect() login$ = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_LOGIN),
		mergeMap((action: AuthAction.login) => this.authService.login(action.payload).pipe(
			map((user: any) => {
				//this.router.navigate([`/`]);
				window.location.href = '/';
				return [new AuthAction.loginSuccess({ user: user.user })];
			}),
			catchError(error => {
				this.gs.handleErrors(error);
				return [new AuthAction.loginFailure(error)]
			})
		))
	);

	/*@Effect() login: Observable<Action> = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_LOGIN),
		map(action => action),
		switchMap((payloads: AuthAction.login) => {
			return this.authService.login(payloads.payload).pipe(
				map((data: any) => {
					console.log('dfgd', data);
				}),
			);
		}),
		switchMap((data) => {
			console.log('dfgd12345', data);
			return [];
		}),
		catchError(error => {
			this.gs.handleErrors(error);
			return [new AuthAction.loginFailure(error)]
		})
	 );*/

	@Effect()
	register$: Observable<Action> = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_REGISTER),
		mergeMap((action: AuthAction.register) => this.authService.register(action.payload).pipe(
			map((user: any) => new AuthAction.loginMessage({ msg: 'Check your email to complete the sign up process', url: '/login' })),
			catchError(error => {
				this.gs.handleErrors(error);
				return [new AuthAction.loginFailure(error)]
			})
		))
	);

	@Effect()
	forgotPassword$: Observable<Action> = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_FORGOTPASSWORD),
		mergeMap((action: AuthAction.forgotPassword) => this.commonService.forgotPassword(action.payload).pipe(
			map((user: any) => new AuthAction.loginMessage({ msg: 'Check your email for further instructions.', url: '/login' })),
			catchError(error => {
				this.gs.handleErrors(error);
				return [new AuthAction.loginFailure(error)]
			})
		))
	);

	@Effect()
	emailVerification$: Observable<Action> = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_EMAIL_VERIFICATION),
		mergeMap((action: AuthAction.emailVerification) => this.commonService.emailVerification(action.payload).pipe(
			map((user: any) => new AuthAction.loginMessage({ msg: user.message, url: '/login' })),
			catchError(error => {
				this.gs.handleErrors(error);
				return [new AuthAction.loginFailure(error)]
			})
		))
	);

	@Effect()
	resetPassword$: Observable<Action> = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_RESETPASSWORD),
		mergeMap((action: AuthAction.resetPassword) => this.commonService.resetPassword(action.payload.form, { token: action.payload.token }).pipe(
			map((user: any) => new AuthAction.loginMessage({ msg: 'You have successfully update your password.', url: '/login' })),
			catchError(error => {
				this.gs.handleErrors(error);
				return [new AuthAction.loginFailure(error)]
			})
		))
	);

	@Effect()
	logout$: Observable<Action> = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_LOGOUT),
		switchMap((payload: any) => this.commonService.logout()),
		switchMap((res) => {
			this.gs.alert('Logout Successful.', 'bg-success');
			window.location.href = '/';
			return [
				//new authAction.loginLogout(true),
				//new authActions.AuthLoginSuccess(res)
			]
		}), catchError((err: any) => {
			return [new AuthAction.loginFailure({ error: err })]
		})
	);

	/*@Effect({ dispatch: false })
	navigateToProfile$ = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_SUCCESS),
		map((action: AuthAction.loginSuccess) => action.payload),
		tap(() => {
			this.router.navigate([`/`])
		})
	);*/

	@Effect({ dispatch: false })
	handleError$ = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_MESSAGE),
		map((action: AuthAction.loginMessage) => {
			const response = action.payload;
			this.gs.alert(response.msg, 'bg-success');
			this.router.navigate([response.url]);
		}),
		tap((data) => {
			this.router.navigate([`/`])
		})
	);

	@Effect({ dispatch: false })
	localStorage$ = this.actions$.pipe(
		ofType(AuthAction.AuthActionTypes.AUTH_SUCCESS),
		map((action: AuthAction.loginSuccess) => {
			const response = action.payload.user;
			localStorage.setItem('user', JSON.stringify(response));
		})
	);

}
