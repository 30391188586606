<div class="view-box">
    <div class="video">
        <!-- <pre>
            {{item.postVideos | json }}
            {{gs.checkLiveStream(item.postVideos) | json }}
        </pre> -->
        <div *ngIf="(item.postVideos?.is_live === 1) && (item.postVideos?.schedule === 0)">
            <div [innerHtml]="gs.checkLiveStream(item.postVideos) | safeHtml: 'html'"></div>
        </div>
        <div *ngIf="(item.postVideos?.is_live === 0) && (item.postVideos?.schedule === 0)">
            <video *ngIf="!image" #video width="320" height="477" controls [muted]="true" class="w-100" [src]="streamVideo(item)">
                Your browser does not support the video tag. 
            </video>
        </div>
        <a *ngIf="image" routerLink="/timeline/post-view/{{item.id}}">
            <img [src]="item.postVideos.image ? item.postVideos.image.thumb : 'https://uploads.dev.chatdit.com/cache/f/1/K/p/w/T/n/v/f1KpwTnvU2cktUd7uNOkks0v_L01CWLS-500x600.jpg'" alt="" class="img-fluid">
        </a>
    </div>
    <div class="caption">
        <div class="content">
            <time>Date Created: {{ item.created_at * 1000 | date:'MMM d, yy, h:mm a'}}</time>
            <div class="text">
                <read-more [text]="item.content" [maxLength]="250"></read-more>
            </div>
        </div>
        <div class="count-group d-flex align-items-center justify-content-between">
            <div class="count">Number of Views <b>({{gs.shortNumber(item.views_count)}})</b></div>
            <div class="count" (click)="sharePost()"><i class="fas fa-sync-alt"></i> Share <b>({{ item.shareCount }})</b></div>
            <div class="count" (click)="likePost()"><i class="fas fa-thumbs-up"></i> Like <b>({{ item.reactionCount }})</b></div>
        </div>
    </div>
</div>
