import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/helpers/global.service';
import { User, Post, Comment } from 'src/app/models';
import { TimelineService } from 'src/app/modules/timeline/services/timeline.service';
import { CommonService } from 'src/app/service/common.service';
import { ItemService } from 'src/app/service/item.service';
import { UserService } from 'src/app/service/user.service';
import { FeedTimelineAction, ExploreTimelineAction, UserTimelineAction, GroupTimelineAction, PageTimelineAction, EventTimelineAction, CommunityTimelineAction, HiddenPostTimelineAction, HidedMyPostTimelineAction, HideAllPostTimelineAction, AuthActions } from 'src/app/store/action';
import { AuthSelector } from 'src/app/store/selector';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { ReportAbuseComponent } from '../../report-abuse/report-abuse.component';

@Component({
	selector: 'app-post-comments',
	templateUrl: './post-comments.component.html',
	styleUrls: ['./post-comments.component.css']
})
export class PostCommentsComponent implements OnInit {
	@Input() comments: Comment[] | null = null;

	//@Input() public owner: User = null;
	//@Input() public postId: number = null;
	//@Input() public itemId: number = null;
	//@Input() public shareModule: string = null;
	@Input() public module: string = null;
	@Input() public myTimeline: boolean = false;
	@Input() public item: Post = null;
	@Input() public timeline: string = 'Feed';
	// @Output() public replyDelete = new EventEmitter();
	// @Output() public commentDelete = new EventEmitter();
	// @Output() public hideAllCommentOnPost = new EventEmitter();
	// @Output() public loadComments = new EventEmitter();
	@Output() public loads = new EventEmitter();
	userObservable: Observable<User> = this.store.select(AuthSelector.User);
	user: User = null;
	activeEditForm = true;
	activeEditComment = true;
	replyEditFormContent = true;
	replyEditFormId = null;
	replyEditFormId2 = null;

	postDialogRef: MatDialogRef<ReportAbuseComponent>;

	constructor(
		public timelineService: TimelineService,
		public userService: UserService,
		public gs: GlobalService,
		public commonService: CommonService,
		public itemService: ItemService,
		public dialog: MatDialog,
		private router: Router,
		private store: Store<{}>,
	) {
	}

	ngOnInit() {
		this.userObservable.subscribe(data => this.user = data);
		//console.log('commentcommentcomment', this.comment);
		//console.log('comment', this.item, this.comments);
	}

	onlyComment(comment, action = 'hide') {
		const alert = (action === 'hide') ?
			`Do you want to ${action} this comment only?` :
			`Do you want to ${action} this comment only?`;
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: alert
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.itemService.comment("POST", null, { key: 'only-comment', comment_id: comment.id, item_id: this.item.id, module: 'Post' }).subscribe(data => {
					this.gs.alert(`You have successfully ${action} this comment`);
					//this.comment.status = (action === 'hide') ? 3 : 1;
				}, (error => {
					this.gs.handleErrors(error);
				}));
			}
		});
	}

	postComments(comment, action = 'hide') {
		const alert = (action === 'hide') ?
			`Do you want to ${action} all comments from this user on this post?` :
			`Do you want to ${action} all comments from this user on this post?`;
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: alert
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.itemService.comment("POST", null, { key: 'post-comments', friend_id: comment.user.id, item_id: this.item.id, module: 'Post' }).subscribe(data => {
					this.gs.alert(`You have successfully ${action} all comment form this user on this post`);
					this.loads.emit(comment);
				}, (error => {
					this.gs.handleErrors(error);
				}));
			}
		});
	}

	allPostsComment(comment, action = 'hide') {
		const alert = (action === 'hide') ?
			`Do you want to ${action} all comments from this user?` :
			`Do you want to ${action} all comments from this user on this post?`;
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: alert
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.itemService.comment("POST", null, { key: 'all-posts-comment', friend_id: comment.user.id, module: 'Post' }).subscribe(data => {
					this.gs.alert(`You have successfully ${action} all comment form this user.`);
					this.timelineService.followCount('hideAllCommentThisUser');
				}, (error => {
					this.gs.handleErrors(error);
				}));
			}
		});
	}
	//  Hide All Comments from This User------------------------------------

	hideThisCommentReply(comment) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Do you want to Hide this comment only?"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.timelineService.hideUserComment({ comment_id: comment.id }).subscribe(data => {
					this.gs.alert('You have successfully Delete this comment');
					this.loads.emit(comment);
				}, (error1 => {
					console.log(error1);
				}));
			}
		});
	} //  Block user Comments

	blockUser(comment, key?) {
		const alert = (key === 'permanently') ? `Do you want to ${(comment.user.friendState === 5) ? 'Unblock' : 'Block'} This User Permanently?` : `Do you want to take a 30 day ${(comment.user.friendState === 0) ? 'unblock' : 'block'} from seeing this user?`;
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: alert
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.itemService.block("POST", null, { key: 'permanently', friend_id: comment.user.id }).subscribe(data => {
					const item = data.data;
					const msg = (item.status === 1) ? 'You have successfully unblock this user.' : 'You have successfully block this user.';
					this.gs.alert(msg);
					this.viewProfile('update_count');
					let index = this.comments.findIndex(el => el.id === item.id);
					if (key === 'permanently' && item.status === 5) {
						this.comments[index].user.friendState = 5;
					} else if (key === 'temporary' && item.status === 4) {
						this.comments[index].user.friendState = 5;
					} else {
						this.comments[index].user.friendState = 1;
					}
				}, (error1 => {
					this.gs.handleErrors(error1);
				}));
			}
		});
	}

	editComment() {
		this.activeEditComment = false;
	} // Edit comment

	updateCmnt(event) {
		this.activeEditComment = true;
		if (event !== null) {
			let index = this.comments.findIndex(el => el.id === event.id);
			this.comments[index].content = event.content;
		}
	}

	deleteComment(comment) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Do you want to Delete this comment only?"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.itemService.comment("DELETE", null, { comment_id: comment.id }).subscribe(data => {
					this.loads.emit(comment);
					//this.commonService.setAction('removeCommentCount');
					const item = Object.assign({}, this.item, { commentCount: Number(this.item.commentCount) - 1 });
					//this.store.dispatch(new FeedTimelineAction.Update(item));
					switch (this.timeline) {
						case 'Feed':
							this.store.dispatch(new FeedTimelineAction.Update(item));
							break;
						case 'Explore':
							this.store.dispatch(new ExploreTimelineAction.Update(item));
							break;
						case 'user_post':
							this.store.dispatch(new UserTimelineAction.Update(item));
							break;
						case 'group_post':
							this.store.dispatch(new GroupTimelineAction.Update(item));
							break;
						case 'page_post':
							this.store.dispatch(new PageTimelineAction.Update(item));
							break;
						case 'event_post':
							this.store.dispatch(new EventTimelineAction.Update(item));
							break;
						case 'community_post':
							this.store.dispatch(new CommunityTimelineAction.Update(item));
							break;
						case 'hidden_post':
							this.store.dispatch(new HiddenPostTimelineAction.Update(item));
							break;
						case 'hidedmy_post':
							this.store.dispatch(new HidedMyPostTimelineAction.Update(item));
							break;
						case 'hideall_post':
							this.store.dispatch(new HideAllPostTimelineAction.Update(item));
							break;
						// case 'view':
						// 	this.store.dispatch(new UserTimelineAction.View(data.data));
						// 	break;
						default: ;
					}
				});
			}
		});
	} // Delete comment

	/*replyEditComment(item) {
		//console.log(item);
		let commentReplies = this.comment.userComments;
		if (commentReplies && commentReplies.length > 0) {
			commentReplies.forEach(reply => {
				if (item.id === reply.id) {
					this.replyEditFormContent = true;
					this.replyEditFormId = reply.id;
					this.replyEditFormId2 = reply.id;
				}
			});
		}
	}*/

	replyDeleteComment(item) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Do you want to Delete this comment only?"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.itemService.comment("DELETE", null, { comment_id: item.id }).subscribe(data => {
					this.loads.emit(item);
				});
			}
		});
	}

	replyUpdateCmnt(event, item) {
		this.replyEditFormContent = true;
		this.replyEditFormId = null;
		this.replyEditFormId2 = null;
		if (event !== null) {
			this.loads.emit(item);
			/*let commentReplies = this.comment.userComments;
			if (commentReplies && commentReplies.length > 0) {
				commentReplies.forEach(reply => {
					if (item.id === reply.id) {
						reply.content = event.content;
					}
				});
			}*/
		}
	}


	likeComment(comment, type) {
		const params = {
			"item_id": comment.id,
			"module": "UserComment",
			"reaction_id": 1
		}
		this.itemService.reaction("POST", params).subscribe(data => {
			let item = data.data;
			if (type === 'comment') {
				if (item.item === comment.id) {

					//this.comment.reactionCount = item.reactionCount;
					//this.comment.isLike = (this.comment.isLike === false);
				}
			} else {
				const commentReplies = comment.userComments;
				if (commentReplies && commentReplies.length > 0) {
					/*this.comment.userComments.forEach(reply => {
						if (item.item === reply.id) {
							reply.reactionCount = item.reactionCount;
							reply.isLike = (reply.isLike === false);
						}
					});*/
				}
			}
			this.gs.alert(`You have successfully like ${type} in timeline.`);
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	} //Like comment

	// Comment Share
	commentShare(comment) {
		const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
			width: '350px',
			data: "Do you confirm share the comment?"
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				const form = {
					PostForm: {
						content: comment.content,
						visibility: 1,
						parent_id: this.item.id,
						item_id: this.item.item_id, // group_id
						module: this.item.module, // group module
						refer_id: comment.id,
						refer_module: "UserComment",
					}
				}
				this.itemService.post("POST", form).subscribe((data: any) => {
					this.gs.alert('You have successfully share comment in timeline.');
					//this.commonService.postAction(data.data);
					switch (this.timeline) {
						case 'Feed':
							this.store.dispatch(new FeedTimelineAction.Add(data.data));
							break;
						case 'Explore':
							this.store.dispatch(new ExploreTimelineAction.Add(data.data));
							break;
						case 'user_post':
							this.store.dispatch(new UserTimelineAction.Add(data.data));
							break;
						case 'group_post':
							this.store.dispatch(new GroupTimelineAction.Add(data.data));
							break;
						case 'page_post':
							this.store.dispatch(new PageTimelineAction.Add(data.data));
							break;
						case 'event_post':
							this.store.dispatch(new EventTimelineAction.Add(data.data));
							break;
						case 'community_post':
							this.store.dispatch(new CommunityTimelineAction.Add(data.data));
							break;
						case 'hidden_post':
							this.store.dispatch(new HiddenPostTimelineAction.Add(data.data));
							break;
						case 'hidedmy_post':
							this.store.dispatch(new HidedMyPostTimelineAction.Add(data.data));
							break;
						case 'hideall_post':
							this.store.dispatch(new HideAllPostTimelineAction.Add(data.data));
							break;
						case 'view':
							this.store.dispatch(new UserTimelineAction.View(data.data));
							break;
						default: ;
					}
					//this.comment.shareCount = parseInt(this.comment.shareCount) + 1;
				}, (error: Response) => {
					this.gs.handleErrors(error);
				});
			}
		});
	}

	// User Comment report abuse
	reportComment(id) {
		this.postDialogRef = this.dialog.open(ReportAbuseComponent, {
			width: '550px',
			disableClose: true,
			panelClass: 'custom-dialog',
			data: {
				id: id,
				module: 'UserComment',
			}
		});
	}

	// Follow user
	followUser(item, action) {
		const method = (action === 'follow') ? "POST" : "DELETE";
		this.userService.follow(method, null, { following_id: item.user.id }).subscribe(data => {
			this.gs.alert(`You have successfully ${action} this user.`);
			this.itemService.followCount('update_count');
			item.user.is_follow = (action === 'follow') ? true : false;
			let index = this.comments.findIndex(el => el.id === data.id);
			this.comments[index] = item;
			//this.comment = item;
			this.viewProfile('update_count');
		}, (error: Response) => {
			this.gs.handleErrors(error);
		});
	}

	viewFriend(slug) {
		this.router.navigate(['/user/friend/timeline/', slug]);
		this.userService.friendSlug(slug);
		this.userService.profile("GET", null, { username: slug }).subscribe((data: any) => {
			sessionStorage.setItem('public', JSON.stringify(data));
		}, (error: Response) => {
			this.gs.handleErrors(error)
		});
	}

	viewProfile(action?) {
		this.userService.profile().subscribe((response: any) => {
			this.store.dispatch(new AuthActions.loginSuccess({ user: response }));
		});
	}

}
