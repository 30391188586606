import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/helpers/global.service';
import { Item } from 'src/app/models/Item';
import { DefaultService } from 'src/app/service/default.service';
import { DefaultAction } from 'src/app/store/action';
import { DefaultSelector } from 'src/app/store/selector';

@Component({
	selector: 'app-advertisement',
	templateUrl: './advertisement.component.html',
	styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {
	advertisements: Observable<Item[]> = this.store.select(DefaultSelector.advertisement);
	constructor(
		public gs: GlobalService,
		public defaultService: DefaultService,
		private store: Store<{}>,
	) { }

	ngOnInit() {
		this.advertisements.subscribe(data => {
			(data === null) && this.store.dispatch(new DefaultAction.AdvertisementParams({params: {moduleId: 'advertisement', status: 1}}));
		});
	}

	advertisementtOptions: OwlOptions = {
		loop: true,
		touchDrag: false,
		pullDrag: false,
		dots: false,
		items: 1,
		nav: false,
		margin: 20,
		navSpeed: 700,
		autoplay: true,
		autoplayTimeout: 6000,
		//animateOut: 'fade',
		//animateIn: 'fade',
		navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
	}

}
