<form name="location" (ngSubmit)="onSubmit(form)" [formGroup]="form">
<div class="content">
    {{title}}
</div>
<!-- <div class="formdiv">
    <div class="input-group">
        <select class="custom-select" id="inputGroupSelect04" aria-label="Example select with button addon">
          <option selected>Choose...</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </select>
        <div class="input-group-append">
            <input type="text" class="form-control" aria-label="Text input with segmented dropdown button" phoneMask>
        </div>
    </div>
</div> -->
<div class="formdiv">
    <div class="d-flex">
        <!-- {{localCode | json}} -->
        <select style="width: 60px; height: 54px; margin-right: 4px; margin-top: 6px;" [(ngModel)]="localCode" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let country of filteredCountry" [value]="country.phone_code">
                {{country.phone_code}} - {{ country.name }}</option>
        </select>
        <div style="width: 163px;">
            <mat-form-field appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input matInput type="text" placeholder="456-545-4546" formControlName="phone" required phoneMask>
                <!-- mask="000-000-0000" prefix="{{localCode}}" -->
                <!-- <mat-hint [align]="'start'"><strong>Type number with country iso code </strong> </mat-hint> -->
                <mat-error *ngIf="f.phone.errors && f.phone.touched">
                    <div *ngIf="f.phone.errors.required">Phone number is required</div>
                    <div *ngIf="f.phone.errors.pattern">Phone no not valid (456-545-4546)</div>
                    <div *ngIf="f.phone.errors.maxLength">Phone no maximum 10 digit only</div>
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <mat-form-field appearance="outline">
        <mat-label>Email Address</mat-label>
        <input matInput placeholder="Email" formControlName="email" required>
        <mat-error *ngIf="f.email.errors && f.email.touched">
            <div *ngIf="f.email.errors.required">Email address is required</div>
            <div *ngIf="f.email.errors.email">Email address not valid</div>
        </mat-error>
    </mat-form-field>
    <div class="btndiv">
        <button type="submit" class="btn btn-primary" [ladda]="submitted">submit</button>
    </div>
</div>
</form>