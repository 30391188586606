<ng-container *ngIf="requestLoading else loaded">
	<div *ngIf="items && items?.length == 0">News not found</div>
	<owl-carousel-o [options]="storyOptions">
		<ng-template carouselSlide *ngFor="let item of items">
			<div class="story-box">
				<div class="image mb-3">
					<img src="{{ item.live_image_url}}" alt="" class="img-fluid">
				</div>
				<div class="caption">
					<h5 class="">
						<ng-container *ngIf="item.external_url else loaded2">
							<a [href]="item.external_url" target="_blank">{{item.title | slice:0:50}}</a>
						</ng-container>
						<ng-template #loaded2>
							<a routerLink="/news/view/{{item.slug}}">{{item.title | slice:0:50}}</a>
						</ng-template>
					</h5>
					<p class="text" [innerHtml]="item.content | slice:0:70"></p>
					<p class="read">
						<ng-container *ngIf="item.external_url else loaded2">
							<a [href]="item.external_url" target="_blank">Read More...</a>
						</ng-container>
						<ng-template #loaded2>
							<a routerLink="/news/view/{{item.slug}}">Read More...</a>
						</ng-template>
					</p>
				</div>
			</div>
		</ng-template>
	</owl-carousel-o>
</ng-container>
<ng-template #loaded>
	<div class="d-flex align-items-center justify-content-center w-100">
		<mat-spinner color="primary" class="spinner-size"></mat-spinner>
	</div>
</ng-template>
